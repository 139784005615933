.column__wrapper {
  display: flex;
  flex: 1 1 auto;
  position: relative;
}

.columns-area {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: flex-start;
  overflow-x: auto;
  position: relative;

  &__panels {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;

    &__pane {
      height: 100%;
      overflow: hidden;
      pointer-events: none;
      display: flex;
      justify-content: flex-end;
      min-width: 285px;

      &--start {
        justify-content: flex-start;
      }

      &__inner {
        position: fixed;
        width: 285px;
        pointer-events: auto;
        height: 100%;
      }
    }

    &__main {
      box-sizing: border-box;
      width: 100%;
      max-width: 600px;
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;

      @media screen and (min-width: $no-gap-breakpoint) {
        padding: 0 10px;
      }
    }
  }
}

.tabs-bar__wrapper {
  background: darken($ui-base-color, 8%);
  position: sticky;
  top: 0;
  z-index: 2;
  padding-top: 0;

  @media screen and (min-width: $no-gap-breakpoint) {
    padding-top: 10px;
  }

  .tabs-bar {
    margin-bottom: 0;

    @media screen and (min-width: $no-gap-breakpoint) {
      margin-bottom: 10px;
    }
  }
}

.react-swipeable-view-container {
  &,
  .columns-area,
  .column {
    height: 100%;
  }
}

.react-swipeable-view-container > * {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.column {
  width: 330px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  > .scrollable {
    background: $ui-base-color;
  }
}

.ui {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.column {
  overflow: hidden;
}

.column-back-button {
  box-sizing: border-box;
  width: 100%;
  background: lighten($ui-base-color, 4%);
  border-radius: 4px 4px 0 0;
  color: $highlight-text-color;
  cursor: pointer;
  flex: 0 0 auto;
  font-size: 16px;
  border: 0;
  text-align: unset;
  padding: 15px;
  margin: 0;
  z-index: 3;

  &:hover {
    text-decoration: underline;
  }
}

.column-header__back-button {
  background: lighten($ui-base-color, 4%);
  border: 0;
  font-family: inherit;
  color: $highlight-text-color;
  cursor: pointer;
  flex: 0 0 auto;
  font-size: 16px;
  padding: 0 5px 0 0;
  z-index: 3;

  &:hover {
    text-decoration: underline;
  }

  &:last-child {
    padding: 0 15px 0 0;
  }
}

.column-back-button__icon {
  display: inline-block;
  margin-right: 5px;
}

.column-back-button--slim {
  position: relative;
}

.column-back-button--slim-button {
  cursor: pointer;
  flex: 0 0 auto;
  font-size: 16px;
  padding: 15px;
  position: absolute;
  right: 0;
  top: -48px;
}

.column-link {
  background: lighten($ui-base-color, 8%);
  color: $primary-text-color;
  display: block;
  font-size: 16px;
  padding: 15px;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    background: lighten($ui-base-color, 11%);
  }

  &:focus {
    outline: 0;
  }

  &--transparent {
    background: transparent;
    color: $ui-secondary-color;

    &:hover,
    &:focus,
    &:active {
      background: transparent;
      color: $primary-text-color;
    }

    &.active {
      color: $highlight-text-color;
    }
  }

  &--logo {
    background: transparent;
    padding: 10px;

    &:hover,
    &:focus,
    &:active {
      background: transparent;
    }
  }
}

.column-link__icon {
  display: inline-block;
  margin-right: 5px;
}

.column-subheading {
  background: $ui-base-color;
  color: $dark-text-color;
  padding: 8px 20px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: default;
}

.column-header__wrapper {
  position: relative;
  flex: 0 0 auto;
  z-index: 1;

  &.active {
    box-shadow: 0 1px 0 rgba($highlight-text-color, 0.3);

    &::before {
      display: block;
      content: "";
      position: absolute;
      bottom: -13px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 60%;
      pointer-events: none;
      height: 28px;
      z-index: 1;
      background: radial-gradient(ellipse, rgba($ui-highlight-color, 0.23) 0%, rgba($ui-highlight-color, 0) 60%);
    }
  }

  .announcements {
    z-index: 1;
    position: relative;
  }
}

.column-header {
  display: flex;
  font-size: 16px;
  background: lighten($ui-base-color, 4%);
  border-radius: 4px 4px 0 0;
  flex: 0 0 auto;
  cursor: pointer;
  position: relative;
  z-index: 2;
  outline: 0;
  overflow: hidden;

  & > button {
    margin: 0;
    border: 0;
    padding: 15px;
    color: inherit;
    background: transparent;
    font: inherit;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex: 1;
  }

  & > .column-header__back-button {
    color: $highlight-text-color;
  }

  &.active {
    .column-header__icon {
      color: $highlight-text-color;
      text-shadow: 0 0 10px rgba($ui-highlight-color, 0.4);
    }
  }

  &:focus,
  &:active {
    outline: 0;
  }
}

.column {
  width: 330px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .wide .columns-area:not(.columns-area--mobile) & {
    flex: auto;
    min-width: 330px;
    max-width: 400px;
  }

  > .scrollable {
    background: $ui-base-color;
    border-radius: 0 0 4px 4px;
  }
}

.column-header__buttons {
  height: 48px;
  display: flex;
  margin-left: 0;
}

.column-header__links {
  margin-bottom: 14px;
}

.column-header__links .text-btn {
  margin-right: 10px;
}

.column-header__button {
  background: lighten($ui-base-color, 4%);
  border: 0;
  color: $darker-text-color;
  cursor: pointer;
  font-size: 16px;
  padding: 0 15px;

  &:hover {
    color: lighten($darker-text-color, 7%);
  }

  &.active {
    color: $primary-text-color;
    background: lighten($ui-base-color, 8%);

    &:hover {
      color: $primary-text-color;
      background: lighten($ui-base-color, 8%);
    }
  }

  // glitch - added focus ring for keyboard navigation
  &:focus {
    text-shadow: 0 0 4px darken($ui-highlight-color, 5%);
  }

  &:disabled {
    color: $dark-text-color;
    cursor: default;
  }
}

.column-header__notif-cleaning-buttons {
  display: flex;
  align-items: stretch;
  justify-content: space-around;

  button {
    @extend .column-header__button;
    background: transparent;
    text-align: center;
    padding: 10px 5px;
    font-size: 14px;
  }

  b {
    font-weight: bold;
  }
}


.layout-single-column .column-header__notif-cleaning-buttons {
  @media screen and (min-width: $no-gap-breakpoint) {
    b, i {
      margin-right: 5px;
    }

    br {
      display: none;
    }

    button {
      padding: 15px 5px;
    }
  }
}

// The notifs drawer with no padding to have more space for the buttons
.column-header__collapsible-inner.nopad-drawer {
  padding: 0;
}

.column-header__collapsible {
  max-height: 70vh;
  overflow: hidden;
  overflow-y: auto;
  color: $darker-text-color;
  transition: max-height 150ms ease-in-out, opacity 300ms linear;
  opacity: 1;
  z-index: 1;
  position: relative;

  &.collapsed {
    max-height: 0;
    opacity: 0.5;
  }

  &.animating {
    overflow-y: hidden;
  }

  hr {
    height: 0;
    background: transparent;
    border: 0;
    border-top: 1px solid lighten($ui-base-color, 12%);
    margin: 10px 0;
  }

  // notif cleaning drawer
  &.ncd {
    transition: none;
    &.collapsed {
      max-height: 0;
      opacity: 0.7;
    }
  }
}

.column-header__collapsible-inner {
  background: lighten($ui-base-color, 8%);
  padding: 15px;
}

.column-header__setting-btn {
  &:hover,
  &:focus {
    color: $darker-text-color;
    text-decoration: underline;
  }
}

.column-header__collapsible__extra + .column-header__setting-btn {
  padding-top: 5px;
}

.column-header__permission-btn {
  display: inline;
  font-weight: inherit;
  text-decoration: underline;
}

.column-header__setting-arrows {
  float: right;

  .column-header__setting-btn {
    padding: 5px;

    &:first-child {
      padding-right: 7px;
    }

    &:last-child {
      padding-left: 7px;
      margin-left: 5px;
    }
  }
}

.column-header__title {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
}

.column-header__issue-btn {
  color: $warning-red;

  &:hover {
    color: $error-red;
    text-decoration: underline;
  }
}

.column-header__icon {
  display: inline-block;
  margin-right: 5px;
}

.column-settings__pillbar {
  display: flex;
  overflow: hidden;
  background-color: transparent;
  border: 0;
  border-radius: 4px;
  margin-bottom: 10px;
  align-items: stretch;
}

.pillbar-button {
  border: 0;
  color: #fafafa;
  padding: 2px;
  margin: 0;
  margin-left: 2px;
  font-size: inherit;
  flex: auto;
  background-color: $ui-base-color;
  transition-property: background-color, box-shadow;
  transition: all 0.2s ease;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:not([disabled]) {
    &:hover {
      background-color: darken($ui-base-color, 10%);
    }

    &:focus {
      background-color: darken($ui-base-color, 15%);
    }

    &:active {
      background-color: darken($ui-base-color, 20%);
    }

    &.active {
      background-color: $ui-highlight-color;
      box-shadow: inset 0 5px darken($ui-highlight-color, 20%);

      &:hover {
        background-color: lighten($ui-highlight-color, 10%);
        box-shadow: inset 0 5px darken($ui-highlight-color, 10%);
      }

      &:focus {
        background-color: lighten($ui-highlight-color, 15%);
        box-shadow: inset 0 5px darken($ui-highlight-color, 5%);
      }

      &:active {
        background-color: lighten($ui-highlight-color, 20%);
        box-shadow: inset 0 5px $ui-highlight-color;
      }
    }
  }

  /* TODO: check RTL? */
  &:first-child {
    margin-left: 0;
  }
}

.limited-account-hint {
  p {
    color: $secondary-text-color;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 20px;
  }
}

.empty-column-indicator,
.error-column,
.follow_requests-unlocked_explanation {
  color: $dark-text-color;
  background: $ui-base-color;
  text-align: center;
  padding: 20px;
  font-size: 15px;
  font-weight: 400;
  cursor: default;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  @supports(display: grid) { // hack to fix Chrome <57
    contain: strict;
  }

  & > span {
    max-width: 500px;
  }

  a {
    color: $highlight-text-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.follow_requests-unlocked_explanation {
  background: darken($ui-base-color, 4%);
  contain: initial;
}

.error-column {
  flex-direction: column;
}

// more fixes for the navbar-under mode
@mixin fix-margins-for-navbar-under {
  .tabs-bar {
    margin-top: 0 !important;
    margin-bottom: -6px !important;
  }
}

.single-column.navbar-under {
  @include fix-margins-for-navbar-under;
}

.auto-columns.navbar-under {
  @media screen and (max-width: $no-gap-breakpoint) {
    @include fix-margins-for-navbar-under;
  }
}

.auto-columns.navbar-under .react-swipeable-view-container .columns-area,
.single-column.navbar-under .react-swipeable-view-container .columns-area {
  @media screen and (max-width: $no-gap-breakpoint) {
    height: 100% !important;
  }
}

.column-inline-form {
  padding: 7px 15px;
  padding-right: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: lighten($ui-base-color, 4%);

  label {
    flex: 1 1 auto;

    input {
      width: 100%;
      margin-bottom: 6px;

      &:focus {
        outline: 0;
      }
    }
  }

  .icon-button {
    flex: 0 0 auto;
    margin: 0 5px;
  }
}

.column-settings__outer {
  background: lighten($ui-base-color, 8%);
  padding: 15px;
}

.column-settings__section {
  color: $darker-text-color;
  cursor: default;
  display: block;
  font-weight: 500;
  margin-bottom: 10px;
}

.column-settings__row--with-margin {
  margin-bottom: 15px;
}

.column-settings__hashtags {
  .column-settings__row {
    margin-bottom: 15px;
  }

  .column-select {
    &__control {
      @include search-input();

      &::placeholder {
        color: lighten($darker-text-color, 4%);
      }

      &::-moz-focus-inner {
        border: 0;
      }

      &::-moz-focus-inner,
      &:focus,
      &:active {
        outline: 0 !important;
      }

      &:focus {
        background: lighten($ui-base-color, 4%);
      }

      @media screen and (max-width: 600px) {
        font-size: 16px;
      }
    }

    &__placeholder {
      color: $dark-text-color;
      padding-left: 2px;
      font-size: 12px;
    }

    &__value-container {
      padding-left: 6px;
    }

    &__multi-value {
      background: lighten($ui-base-color, 8%);

      &__remove {
        cursor: pointer;

        &:hover,
        &:active,
        &:focus {
          background: lighten($ui-base-color, 12%);
          color: lighten($darker-text-color, 4%);
        }
      }
    }

    &__multi-value__label,
    &__input,
    &__input-container {
      color: $darker-text-color;
    }

    &__clear-indicator,
    &__dropdown-indicator {
      cursor: pointer;
      transition: none;
      color: $dark-text-color;

      &:hover,
      &:active,
      &:focus {
        color: lighten($dark-text-color, 4%);
      }
    }

    &__indicator-separator {
      background-color: lighten($ui-base-color, 8%);
    }

    &__menu {
      @include search-popout();
      padding: 0;
      background: $ui-secondary-color;
    }

    &__menu-list {
      padding: 6px;
    }

    &__option {
      color: $inverted-text-color;
      border-radius: 4px;
      font-size: 14px;

      &--is-focused,
      &--is-selected {
        background: darken($ui-secondary-color, 10%);
      }
    }
  }
}

.column-settings__row {
  .text-btn:not(.column-header__permission-btn) {
    margin-bottom: 15px;
  }
}

.notifications-permission-banner {
  padding: 30px;
  border-bottom: 1px solid lighten($ui-base-color, 8%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  h2 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
    text-align: center;
  }

  p {
    color: $darker-text-color;
    margin-bottom: 15px;
    text-align: center;
  }
}

.column-title {
  text-align: center;
  padding: 40px;

  .logo {
    width: 50px;
    margin: 0 auto;
    margin-bottom: 40px;
  }

  h3 {
    font-size: 24px;
    line-height: 1.5;
    font-weight: 700;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
   line-height: 24px;
    font-weight: 400;
    color: $darker-text-color;
  }
}

.follow-recommendations-container {
  display: flex;
  flex-direction: column;
}

.column-actions {
  display: flex;
  align-items: start;
  justify-content: center;
  padding: 40px;
  padding-top: 40px;
  padding-bottom: 200px;
  flex-grow: 1;
  position: relative;

  &__background {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 220px;
    width: auto;
  }
}

.column-list {
  margin: 0 20px;
  border: 1px solid lighten($ui-base-color, 8%);
  background: darken($ui-base-color, 2%);
  border-radius: 4px;

  &__empty-message {
    padding: 40px;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: $darker-text-color;
  }
}
