@keyframes spring-flip-in {
  0% {
    transform: rotate(0deg);
  }

  30% {
    transform: rotate(-242.4deg);
  }

  60% {
    transform: rotate(-158.35deg);
  }

  90% {
    transform: rotate(-187.5deg);
  }

  100% {
    transform: rotate(-180deg);
  }
}

@keyframes spring-flip-out {
  0% {
    transform: rotate(-180deg);
  }

  30% {
    transform: rotate(62.4deg);
  }

  60% {
    transform: rotate(-21.635deg);
  }

  90% {
    transform: rotate(7.5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.status__content--with-action {
  cursor: pointer;
}

.status__content {
  position: relative;
  margin: 10px 0;
  font-size: 15px;
  line-height: 20px;
  word-wrap: break-word;
  font-weight: 400;
  overflow: visible;
  padding-top: 5px;
  clear: both;

  &:focus {
    outline: 0;
  }

  .emojione {
    width: 20px;
    height: 20px;
    margin: -3px 0 0;
  }

  p, pre, blockquote {
    margin-bottom: 20px;
    white-space: pre-wrap;
    unicode-bidi: plaintext;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .status__content__text,
  .e-content {
    overflow: hidden;

    & > ul,
    & > ol {
      margin-bottom: 20px;
    }

    h1, h2, h3, h4, h5 {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    h1, h2 {
      font-weight: 700;
      font-size: 1.2em;
    }

    h2 {
      font-size: 1.1em;
    }

    h3, h4, h5 {
      font-weight: 500;
    }

    blockquote {
      padding-left: 10px;
      border-left: 3px solid $darker-text-color;
      color: $darker-text-color;
      white-space: normal;

      p:last-child {
        margin-bottom: 0;
      }
    }

    b, strong {
      font-weight: 700;
    }

    em, i {
      font-style: italic;
    }

    sub {
      font-size: smaller;
      vertical-align: sub;
    }

    sup {
      font-size: smaller;
      vertical-align: super;
    }

    ul, ol {
      margin-left: 1em;

      p {
        margin: 0;
      }
    }

    ul {
      list-style-type: disc;
    }

    ol {
      list-style-type: decimal;
    }
  }

  a {
    color: $secondary-text-color;
    text-decoration: none;
    unicode-bidi: isolate;

    &:hover {
      text-decoration: underline;

      .fa {
        color: lighten($dark-text-color, 7%);
      }
    }

    &.mention {
      &:hover {
        text-decoration: none;

        span {
          text-decoration: underline;
        }
      }
    }

    .fa {
      color: $dark-text-color;
    }
  }

  .status__content__spoiler {
    display: none;

    &.status__content__spoiler--visible {
      display: block;
    }
  }

  a.unhandled-link {
    color: $highlight-text-color;

    .link-origin-tag {
      color: $gold-star;
      font-size: 0.8em;
    }
  }

  .status__content__spoiler-link {
    background: lighten($ui-base-color, 30%);

    &:hover,
    &:focus {
      background: lighten($ui-base-color, 33%);
      text-decoration: none;
    }
  }
}

.status__content__edited-label {
  display: block;
  cursor: default;
  font-size: 15px;
  line-height: 20px;
  padding: 0;
  padding-top: 8px;
  color: $dark-text-color;
  font-weight: 500;
}

.status__content__spoiler-link {
  display: inline-block;
  border-radius: 2px;
  background: lighten($ui-base-color, 30%);
  border: 0;
  color: $inverted-text-color;
  font-weight: 700;
  font-size: 11px;
  padding: 0 5px;
  text-transform: uppercase;
  line-height: inherit;
  cursor: pointer;
  vertical-align: top;

  &:hover {
    background: lighten($ui-base-color, 33%);
    text-decoration: none;
  }

  .status__content__spoiler-icon {
    display: inline-block;
    margin: 0 0 0 5px;
    border-left: 1px solid currentColor;
    padding: 0 0 0 4px;
    font-size: 16px;
    vertical-align: -2px;
  }
}

.notif-cleaning {
  .status,
  .notification-follow,
  .notification-follow-request {
    padding-right: ($dismiss-overlay-width + 0.5rem);
  }
}

.status__wrapper--filtered {
  color: $dark-text-color;
  border: 0;
  font-size: inherit;
  text-align: center;
  line-height: inherit;
  margin: 0;
  padding: 15px;
  box-sizing: border-box;
  width: 100%;
  clear: both;
  border-bottom: 1px solid lighten($ui-base-color, 8%);
}

.status__prepend-icon-wrapper {
  left: -26px;
  position: absolute;
}

.notification-follow,
.notification-follow-request {
  position: relative;

  // same like Status
  border-bottom: 1px solid lighten($ui-base-color, 8%);

  .account {
    border-bottom: 0 none;
  }
}

.focusable {
  &:focus {
    outline: 0;
    background: lighten($ui-base-color, 4%);

    &.status.status-direct {
      background: lighten($ui-base-color, 12%);

      &.muted {
        background: transparent;
      }
    }

    .detailed-status,
    .detailed-status__action-bar {
      background: lighten($ui-base-color, 8%);
    }
  }
}

.status {
  padding: 10px 14px;
  position: relative;
  height: auto;
  border-bottom: 1px solid lighten($ui-base-color, 8%);
  cursor: auto;

  @supports (-ms-overflow-style: -ms-autohiding-scrollbar) {
    // Add margin to avoid Edge auto-hiding scrollbar appearing over content.
    // On Edge 16 this is 16px and Edge <=15 it's 12px, so aim for 16px.
    padding-right: 28px; // 12px + 16px
  }

  @keyframes fade {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  opacity: 1;
  animation: fade 150ms linear;

  .video-player,
  .audio-player {
    margin-top: 8px;
  }

  &.status-direct {
    background: lighten($ui-base-color, 8%);
    border-bottom-color: lighten($ui-base-color, 12%);
  }

  &.light {
    .status__relative-time {
      color: $lighter-text-color;
    }

    .status__display-name {
      color: $inverted-text-color;
    }

    .display-name {
      color: $light-text-color;

      strong {
        color: $inverted-text-color;
      }
    }

    .status__content {
      color: $inverted-text-color;

      a {
        color: $highlight-text-color;
      }

      a.status__content__spoiler-link {
        color: $primary-text-color;
        background: $ui-primary-color;

        &:hover {
          background: lighten($ui-primary-color, 8%);
        }
      }
    }
  }

  &.collapsed {
    background-position: center;
    background-size: cover;
    user-select: none;

    &.has-background::before {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-image: linear-gradient(to bottom, rgba($base-shadow-color, .75), rgba($base-shadow-color, .65) 24px, rgba($base-shadow-color, .8));
      pointer-events: none;
      content: "";
    }

    .display-name:hover .display-name__html {
      text-decoration: none;
    }

    .status__content {
      height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-top: 0;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(rgba($ui-base-color, 0), rgba($ui-base-color, 1));
        pointer-events: none;
      }

      a:hover {
        text-decoration: none;
      }
    }
    &:focus > .status__content:after {
      background: linear-gradient(rgba(lighten($ui-base-color, 4%), 0), rgba(lighten($ui-base-color, 4%), 1));
    }
    &.status-direct > .status__content:after {
      background: linear-gradient(rgba(lighten($ui-base-color, 8%), 0), rgba(lighten($ui-base-color, 8%), 1));
    }

    .notification__message {
      margin-bottom: 0;
    }

    .status__info .notification__message > span {
      white-space: nowrap;
    }
  }

  .notification__message {
    margin: -10px 0px 10px 0;
  }
}

.notification-favourite {
  .status.status-direct {
    background: transparent;

    .icon-button.disabled {
      color: lighten($action-button-color, 13%);
    }
  }
}

.status__relative-time {
  display: inline-block;
  flex-grow: 1;
  color: $dark-text-color;
  font-size: 14px;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.status__display-name {
  color: $dark-text-color;
  overflow: hidden;
}

.status__info__account .status__display-name {
  display: block;
  max-width: 100%;
}

.status__info {
  display: flex;
  justify-content: space-between;
  font-size: 15px;

  > span {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .notification__message > span {
    word-wrap: break-word;
  }
}

.status__info__icons {
  display: flex;
  align-items: center;
  height: 1em;
  color: $action-button-color;

  .status__media-icon,
  .status__visibility-icon,
  .status__reply-icon,
  .text-icon {
    padding-left: 2px;
    padding-right: 2px;
  }

  .status__collapse-button.active > .fa-angle-double-up {
    transform: rotate(-180deg);
  }
}

.no-reduce-motion .status__collapse-button {
  &.activate {
    & > .fa-angle-double-up {
      animation: spring-flip-in 1s linear;
    }
  }

  &.deactivate {
    & > .fa-angle-double-up {
      animation: spring-flip-out 1s linear;
    }
  }
}

.status__info__account {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.status-check-box__status {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 0 10px;

  .detailed-status__display-name {
    color: lighten($inverted-text-color, 16%);

    span {
      display: inline;
    }

    &:hover strong {
      text-decoration: none;
    }
  }

  .media-gallery,
  .audio-player,
  .video-player {
    margin-top: 15px;
    max-width: 250px;
  }

  .status__content {
    padding: 0;
    white-space: normal;
  }

  .media-gallery__item-thumbnail {
    cursor: default;
  }
}

.status__prepend {
  margin-top: -2px;
  margin-bottom: 8px;
  margin-left: 58px;
  color: $dark-text-color;
  font-size: 14px;
  position: relative;

  .status__display-name strong {
    color: $dark-text-color;
  }

  > span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.status__action-bar {
  align-items: center;
  display: flex;
  margin-top: 8px;
}

.status__action-bar-button {
  margin-right: 18px;

  &.icon-button--with-counter {
    margin-right: 14px;
  }
}

.status__action-bar-dropdown {
  height: 23.15px;
  width: 23.15px;
}

.detailed-status__action-bar-dropdown {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.detailed-status {
  background: lighten($ui-base-color, 4%);
  padding: 14px 10px;

  &--flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

    .status__content,
    .detailed-status__meta {
      flex: 100%;
    }
  }

  .status__content {
    font-size: 19px;
    line-height: 24px;

    .emojione {
      width: 24px;
      height: 24px;
      margin: -1px 0 0;
    }
  }

  .video-player,
  .audio-player {
    margin-top: 8px;
  }
}

.detailed-status__meta {
  margin-top: 15px;
  color: $dark-text-color;
  font-size: 14px;
  line-height: 18px;
}

.detailed-status__action-bar {
  background: lighten($ui-base-color, 4%);
  border-top: 1px solid lighten($ui-base-color, 8%);
  border-bottom: 1px solid lighten($ui-base-color, 8%);
  display: flex;
  flex-direction: row;
  padding: 10px 0;
}

.detailed-status__link {
  color: inherit;
  text-decoration: none;
}

.detailed-status__favorites,
.detailed-status__reblogs {
  display: inline-block;
  font-weight: 500;
  font-size: 12px;
  margin-left: 6px;
}

.status__display-name,
.status__relative-time,
.detailed-status__display-name,
.detailed-status__datetime,
.detailed-status__application,
.account__display-name {
  text-decoration: none;
}

.status__display-name,
.account__display-name {
  strong {
    color: $primary-text-color;
  }
}

.muted {
  .emojione {
    opacity: 0.5;
  }
}

a.status__display-name,
.reply-indicator__display-name,
.detailed-status__display-name,
.account__display-name {
  &:hover strong {
    text-decoration: underline;
  }
}

.account__display-name strong {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.detailed-status__application,
.detailed-status__datetime {
  color: inherit;
}

.detailed-status .button.logo-button {
  margin-bottom: 15px;
}

.detailed-status__display-name {
  color: $secondary-text-color;
  display: block;
  line-height: 24px;
  margin-bottom: 15px;
  overflow: hidden;

  strong,
  span {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  strong {
    font-size: 16px;
    color: $primary-text-color;
  }
}

.detailed-status__display-avatar {
  float: left;
  margin-right: 10px;
}

.status__avatar {
  flex: none;
  margin: 0 10px 0 0;
  height: 48px;
  width: 48px;
}

.muted {
  .status__content,
  .status__content p,
  .status__content a,
  .status__content__text {
    color: $dark-text-color;
  }

  .status__display-name strong {
    color: $dark-text-color;
  }

  .status__avatar {
    opacity: 0.5;
  }

  a.status__content__spoiler-link {
    background: $ui-base-lighter-color;
    color: $inverted-text-color;

    &:hover,
    &:focus {
      background: lighten($ui-base-color, 29%);
      text-decoration: none;
    }
  }
}

.status__relative-time,
.detailed-status__datetime {
  &:hover {
    text-decoration: underline;
  }
}

.status-card {
  position: relative;
  display: flex;
  font-size: 14px;
  border: 1px solid lighten($ui-base-color, 8%);
  border-radius: 4px;
  color: $dark-text-color;
  margin-top: 14px;
  text-decoration: none;
  overflow: hidden;

  &__actions {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
      background: rgba($base-shadow-color, 0.6);
      border-radius: 8px;
      padding: 12px 9px;
      flex: 0 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    button,
    a {
      display: inline;
      color: $secondary-text-color;
      background: transparent;
      border: 0;
      padding: 0 8px;
      text-decoration: none;
      font-size: 18px;
      line-height: 18px;

      &:hover,
      &:active,
      &:focus {
        color: $primary-text-color;
      }
    }

    a {
      font-size: 19px;
      position: relative;
      bottom: -1px;
    }

    a .fa, a:hover .fa {
      color: inherit;
    }
  }
}

a.status-card {
  cursor: pointer;

  &:hover {
    background: lighten($ui-base-color, 8%);
  }
}

.status-card-photo {
  cursor: zoom-in;
  display: block;
  text-decoration: none;
    width: 100%;
    height: auto;
    margin: 0;
}

.status-card-video {
  iframe {
    width: 100%;
    height: 100%;
  }
}

.status-card__title {
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
  color: $darker-text-color;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
}

.status-card__content {
  flex: 1 1 auto;
  overflow: hidden;
  padding: 14px 14px 14px 8px;
}

.status-card__description {
  color: $darker-text-color;
}

.status-card__host {
  display: block;
  margin-top: 5px;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.status-card__image {
  flex: 0 0 100px;
  background: lighten($ui-base-color, 8%);
  position: relative;

  & > .fa {
    font-size: 21px;
    position: absolute;
    transform-origin: 50% 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.status-card.horizontal {
  display: block;

  .status-card__image {
    width: 100%;
  }

  .status-card__image-image,
  .status-card__image-preview {
    border-radius: 4px 4px 0 0;
  }

  .status-card__title {
    white-space: inherit;
  }
}

.status-card.compact {
  border-color: lighten($ui-base-color, 4%);

  &.interactive {
    border: 0;
  }

  .status-card__content {
    padding: 8px;
    padding-top: 10px;
  }

  .status-card__title {
    white-space: nowrap;
  }

  .status-card__image {
    flex: 0 0 60px;
  }
}

a.status-card.compact:hover {
  background-color: lighten($ui-base-color, 4%);
}

.status-card__image-image {
  border-radius: 4px 0 0 4px;
  display: block;
  margin: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: cover;
  background-position: center center;
}

.status-card__image-preview {
  border-radius: 4px 0 0 4px;
  display: block;
  margin: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background: $base-overlay-background;

  &--hidden {
    display: none;
  }
}

.attachment-list {
  display: flex;
  font-size: 14px;
  border: 1px solid lighten($ui-base-color, 8%);
  border-radius: 4px;
  margin-top: 14px;
  overflow: hidden;

  &__icon {
    flex: 0 0 auto;
    color: $dark-text-color;
    padding: 8px 18px;
    cursor: default;
    border-right: 1px solid lighten($ui-base-color, 8%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 26px;

    .fa {
      display: block;
    }
  }

  &__list {
    list-style: none;
    padding: 4px 0;
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    li {
      display: block;
      padding: 4px 0;
    }

    a {
      text-decoration: none;
      color: $dark-text-color;
      font-weight: 500;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.compact {
    border: 0;
    margin-top: 4px;

    .attachment-list__list {
      padding: 0;
      display: block;
    }

    .fa {
      color: $dark-text-color;
    }
  }
}

.status__wrapper--filtered__button {
  display: inline;
  color: lighten($ui-highlight-color, 8%);
  border: 0;
  background: transparent;
  padding: 0;
  font-size: inherit;
  line-height: inherit;

  &:hover,
  &:active {
    text-decoration: underline;
  }
}

.notification,
.status {
  position: relative;

  &.unread {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: 0;
      width: 100%;
      height: 100%;
      border-left: 2px solid $highlight-text-color;
      pointer-events: none;
    }
  }
}

.picture-in-picture {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;

  &.left {
    right: unset;
    left: 20px;
  }

  &__footer {
    border-radius: 0 0 4px 4px;
    background: lighten($ui-base-color, 4%);
    padding: 10px;
    padding-top: 12px;
    display: flex;
    justify-content: space-between;
  }

  &__header {
    border-radius: 4px 4px 0 0;
    background: lighten($ui-base-color, 4%);
    padding: 10px;
    display: flex;
    justify-content: space-between;

    &__account {
      display: flex;
      text-decoration: none;
      overflow: hidden;
    }

    .account__avatar {
      margin-right: 10px;
    }

    .display-name {
      color: $primary-text-color;
      text-decoration: none;

      strong,
      span {
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      span {
        color: $darker-text-color;
      }
    }
  }

  .video-player,
  .audio-player {
    border-radius: 0;
  }
}

.picture-in-picture-placeholder {
  box-sizing: border-box;
  border: 2px dashed lighten($ui-base-color, 8%);
  background: $base-shadow-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  color: $darker-text-color;

  i {
    display: block;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  &:hover,
  &:focus,
  &:active {
    border-color: lighten($ui-base-color, 12%);
  }
}

.spoilertext {
  .spoilertext--screenreader-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .spoilertext--content {
    margin: -1px 0;
    padding: 0 .5ch;
    box-decoration-break: clone;
    color: $text-on-shadow-color;
    background: rgba($base-shadow-color, .2);
    border: 1px $dark-text-color dashed;
  }

  &:not(.open) .spoilertext--content {
    border-color: transparent;
    color: transparent;
    background: $base-shadow-color;
    user-select: none;
    pointer-events: none;

    img { visibility: hidden }
  }

  .spoilertext--span { white-space: nowrap }

  .spoilertext--button {
    all: unset;
    border-radius: 0 20% 20% 0;
    padding: 0 .5ch;
    color: $text-on-shadow-color;
    background: rgba($base-shadow-color, .4);

    .fa {
      font-style: normal;
      transition: rotate .5s;
    }

    &:focus-visible .fa { rotate: 90deg }
  }
}
