.composer {
  padding: 10px;

  .emoji-picker-dropdown {
    position: absolute;
    top: 0;
    right: 0;

    ::-webkit-scrollbar-track:hover,
    ::-webkit-scrollbar-track:active {
      background-color: rgba($base-overlay-background, 0.3);
    }
  }
}

.character-counter {
  cursor: default;
  font-family: $font-sans-serif, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: $lighter-text-color;

  &.character-counter--over {
    color: $warning-red;
  }
}

.no-reduce-motion .composer--spoiler {
  transition: height 0.4s ease, opacity 0.4s ease;
}

.composer--spoiler {
  height: 0;
  transform-origin: bottom;
  opacity: 0.0;

  &.composer--spoiler--visible {
    height: 36px;
    margin-bottom: 11px;
    opacity: 1.0;
  }

  input {
    display: block;
    box-sizing: border-box;
    margin: 0;
    border: 0;
    border-radius: 4px;
    padding: 10px;
    width: 100%;
    outline: 0;
    color: $inverted-text-color;
    background: $simple-background-color;
    font-size: 14px;
    font-family: inherit;
    resize: vertical;

    &::placeholder {
      color: $dark-text-color;
    }

    &:focus { outline: 0 }
    @include single-column('screen and (max-width: 630px)') { font-size: 16px }
  }
}

.composer--warning {
  color: $inverted-text-color;
  margin-bottom: 15px;
  background: $ui-primary-color;
  box-shadow: 0 2px 6px rgba($base-shadow-color, 0.3);
  padding: 8px 10px;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 400;

  a {
    color: $lighter-text-color;
    font-weight: 500;
    text-decoration: underline;

    &:active,
    &:focus,
    &:hover {
      text-decoration: none;
    }
  }
}

.compose-form__sensitive-button {
  padding: 10px;
  padding-top: 0;

  font-size: 14px;
  font-weight: 500;

  &.active {
    color: $highlight-text-color;
  }

  input[type=checkbox] {
    display: none;
  }

  .checkbox {
    display: inline-block;
    position: relative;
    border: 1px solid $ui-primary-color;
    box-sizing: border-box;
    width: 18px;
    height: 18px;
    flex: 0 0 auto;
    margin-left: 5px;
    margin-right: 10px;
    top: -1px;
    border-radius: 4px;
    vertical-align: middle;

    &.active {
      border-color: $highlight-text-color;
      background: $highlight-text-color;
    }
  }
}

.composer--reply {
  margin: 0 0 10px;
  border-radius: 4px;
  padding: 10px;
  background: $ui-primary-color;
  min-height: 23px;
  overflow-y: auto;
  flex: 0 2 auto;

  & > header {
    margin-bottom: 5px;
    overflow: hidden;

    & > .account.small { color: $inverted-text-color; }

    & > .cancel {
      float: right;
      line-height: 24px;
    }
  }

  & > .content {
    position: relative;
    margin: 10px 0;
    padding: 0 12px;
    font-size: 14px;
    line-height: 20px;
    color: $inverted-text-color;
    word-wrap: break-word;
    font-weight: 400;
    overflow: visible;
    white-space: pre-wrap;
    padding-top: 5px;
    overflow: hidden;

    p, pre, blockquote {
      margin-bottom: 20px;
      white-space: pre-wrap;

      &:last-child {
        margin-bottom: 0;
      }
    }

    h1, h2, h3, h4, h5 {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    h1, h2 {
      font-weight: 700;
      font-size: 18px;
    }

    h2 {
      font-size: 16px;
    }

    h3, h4, h5 {
      font-weight: 500;
    }

    blockquote {
      padding-left: 10px;
      border-left: 3px solid $inverted-text-color;
      color: $inverted-text-color;
      white-space: normal;

      p:last-child {
        margin-bottom: 0;
      }
    }

    b, strong {
      font-weight: 700;
    }

    em, i {
      font-style: italic;
    }

    sub {
      font-size: smaller;
      vertical-align: sub;
    }

    sup {
      font-size: smaller;
      vertical-align: super;
    }

    ul, ol {
      margin-left: 1em;

      p {
        margin: 0;
      }
    }

    ul {
      list-style-type: disc;
    }

    ol {
      list-style-type: decimal;
    }

    a {
      color: $lighter-text-color;
      text-decoration: none;

      &:hover { text-decoration: underline }

      &.mention {
        &:hover {
          text-decoration: none;

          span { text-decoration: underline }
        }
      }
    }
  }

  .emojione {
    width: 20px;
    height: 20px;
    margin: -5px 0 0;
  }
}

.compose-form__autosuggest-wrapper,
.autosuggest-input {
  position: relative;
  width: 100%;

  label {
    .autosuggest-textarea__textarea {
      display: block;
      box-sizing: border-box;
      margin: 0;
      border: 0;
      border-radius: 4px 4px 0 0;
      padding: 10px 32px 0 10px;
      width: 100%;
      min-height: 100px;
      outline: 0;
      color: $inverted-text-color;
      background: $simple-background-color;
      font-size: 14px;
      font-family: inherit;
      resize: none;
      scrollbar-color: initial;

      &::placeholder {
        color: $dark-text-color;
      }

      &::-webkit-scrollbar {
        all: unset;
      }

      &:disabled {
        background: $ui-secondary-color;
      }

      &:focus {
        outline: 0;
      }

      @include single-column('screen and (max-width: 630px)') {
        font-size: 16px;
      }

      @include limited-single-column('screen and (max-width: 600px)') {
        height: 100px !important; // prevent auto-resize textarea
        resize: vertical;
      }
    }
  }
}

.composer--textarea--icons {
  display: block;
  position: absolute;
  top: 29px;
  right: 5px;
  bottom: 5px;
  overflow: hidden;

  & > .textarea_icon {
    display: block;
    margin: 2px 0 0 2px;
    width: 24px;
    height: 24px;
    color: $lighter-text-color;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    opacity: .8;
  }
}

.autosuggest-textarea__suggestions-wrapper {
  position: relative;
  height: 0;
}

.autosuggest-textarea__suggestions {
  display: block;
  position: absolute;
  box-sizing: border-box;
  top: 100%;
  border-radius: 0 0 4px 4px;
  padding: 6px;
  width: 100%;
  color: $inverted-text-color;
  background: $ui-secondary-color;
  box-shadow: 4px 4px 6px rgba($base-shadow-color, 0.4);
  font-size: 14px;
  z-index: 99;
  display: none;
}

.autosuggest-textarea__suggestions--visible {
  display: block;
}

.autosuggest-textarea__suggestions__item {
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;

  &:hover,
  &:focus,
  &:active,
  &.selected { background: darken($ui-secondary-color, 10%) }

  > .account,
  > .emoji,
  > .autosuggest-hashtag {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    line-height: 18px;
    font-size: 14px;
  }

  .autosuggest-hashtag {
    justify-content: space-between;

    &__name {
      flex: 1 1 auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    strong {
      font-weight: 500;
    }

    &__uses {
      flex: 0 0 auto;
      text-align: right;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  & > .account.small {
    .display-name {
      & > span { color: $lighter-text-color }
    }
  }
}

.composer--upload_form {
  overflow: hidden;

  & > .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-family: inherit;
    padding: 5px;
    overflow: hidden;
  }
}

.composer--upload_form--item {
  flex: 1 1 0;
  margin: 5px;
  min-width: 40%;

  & > div {
    position: relative;
    border-radius: 4px;
    height: 140px;
    width: 100%;
    background-color: $base-shadow-color;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;

    & > .close { mix-blend-mode: difference }
  }

  .icon-button {
    flex: 0 1 auto;
    color: $secondary-text-color;
    font-size: 14px;
    font-weight: 500;
    padding: 10px;
    font-family: inherit;

    &:hover,
    &:focus,
    &:active {
      color: lighten($secondary-text-color, 7%);
    }
  }

  &__warning {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    right: 0;
    box-sizing: border-box;
    background: linear-gradient(0deg, rgba($base-shadow-color, 0.8) 0, rgba($base-shadow-color, 0.35) 80%, transparent);
  }
}

.composer--upload_form--actions {
  background: linear-gradient(180deg, rgba($base-shadow-color, 0.8) 0, rgba($base-shadow-color, 0.35) 80%, transparent);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.composer--upload_form--progress {
  display: flex;
  padding: 10px;
  color: $darker-text-color;
  overflow: hidden;

  & > .fa {
    font-size: 34px;
    margin-right: 10px;
  }

  & > .message {
    flex: 1 1 auto;

    & > span {
      display: block;
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
    }

    & > .backdrop {
      position: relative;
      margin-top: 5px;
      border-radius: 6px;
      width: 100%;
      height: 6px;
      background: $ui-base-lighter-color;

      & > .tracker {
        position: absolute;
        top: 0;
        left: 0;
        height: 6px;
        border-radius: 6px;
        background: $ui-highlight-color;
      }
    }
  }
}

.compose-form__modifiers {
  color: $inverted-text-color;
  font-family: inherit;
  font-size: 14px;
  background: $simple-background-color;
}

.composer--options-wrapper {
  padding: 10px;
  background: darken($simple-background-color, 8%);
  border-radius: 0 0 4px 4px;
  height: 27px;
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
}

.composer--options {
  display: flex;
  flex: 0 0 auto;

  & .icon-button,
  & .text-icon-button {
    display: inline-block;
    box-sizing: content-box;
    padding: 0 3px;
    height: 27px;
    line-height: 27px;
    vertical-align: bottom;
  }

  & > hr {
    display: inline-block;
    margin: 0 3px;
    border-width: 0 0 0 1px;
    border-style: none none none solid;
    border-color: transparent transparent transparent darken($simple-background-color, 24%);
    padding: 0;
    width: 0;
    height: 27px;
    background: transparent;
  }
}

.compose--counter-wrapper {
  align-self: center;
  margin-right: 4px;
}

.composer--options--dropdown {
  &.open {
    & > .value {
      border-radius: 4px 4px 0 0;
      box-shadow: 0 -4px 4px rgba($base-shadow-color, 0.1);
      color: $primary-text-color;
      background: $ui-highlight-color;
      transition: none;
    }
    &.top {
      & > .value {
        border-radius: 0 0 4px 4px;
        box-shadow: 0 4px 4px rgba($base-shadow-color, 0.1);
      }
    }
  }
}

.composer--options--dropdown--content {
  position: absolute;
  border-radius: 4px;
  box-shadow: 2px 4px 15px rgba($base-shadow-color, 0.4);
  background: $simple-background-color;
  overflow: hidden;
  transform-origin: 50% 0;
}

.composer--options--dropdown--content--item {
  display: flex;
  align-items: center;
  padding: 10px;
  color: $inverted-text-color;
  cursor: pointer;

  & > .content {
    flex: 1 1 auto;
    color: $lighter-text-color;

    &:not(:first-child) { margin-left: 10px }

    strong {
      display: block;
      color: $inverted-text-color;
      font-weight: 500;
    }
  }

  &:hover,
  &.active {
    background: $ui-highlight-color;
    color: $primary-text-color;

    & > .content {
      color: $primary-text-color;

      strong { color: $primary-text-color }
    }
  }

  &.active:hover { background: lighten($ui-highlight-color, 4%) }
}

.composer--publisher {
  padding-top: 10px;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  justify-content: flex-end;
  flex: 0 0 auto;

  & > .primary {
    display: inline-block;
    margin: 0;
    padding: 7px 10px;
    text-align: center;
  }

  & > .side_arm {
    display: inline-block;
    margin: 0 5px;
    padding: 7px 0;
    width: 36px;
    text-align: center;
  }

  &.over {
    & > .count { color: $warning-red }
  }
}

.language-dropdown {
  &__dropdown {
    position: absolute;
    background: $simple-background-color;
    box-shadow: 2px 4px 15px rgba($base-shadow-color, 0.4);
    border-radius: 4px;
    overflow: hidden;
    z-index: 2;

    &.top {
      transform-origin: 50% 100%;
    }

    &.bottom {
      transform-origin: 50% 0;
    }

    .emoji-mart-search {
      padding-right: 10px;
    }

    .emoji-mart-search-icon {
      right: 10px + 5px;
    }

    .emoji-mart-scroll {
      padding: 0 10px 10px;
    }

    &__results {
      &__item {
        cursor: pointer;
        color: $inverted-text-color;
        font-weight: 500;
        padding: 10px;
        border-radius: 4px;

        &:focus,
        &:active,
        &:hover {
          background: $ui-secondary-color;
        }

        &__common-name {
          color: $darker-text-color;
        }

        &.active {
          background: $ui-highlight-color;
          color: $primary-text-color;
          outline: 0;

          .language-dropdown__dropdown__results__item__common-name {
            color: $secondary-text-color;
          }

          &:hover {
            background: lighten($ui-highlight-color, 4%);
          }
        }
      }
    }
  }
}
