.sign-in-banner {
  padding: 10px;

  p {
    color: $darker-text-color;
    margin-bottom: 20px;
  }

  .button {
    margin-bottom: 10px;
  }
}

.server-banner {
  padding: 20px 0;

  &__introduction {
    color: $darker-text-color;
    margin-bottom: 20px;

    strong {
      font-weight: 600;
    }

    a {
      color: inherit;
      text-decoration: underline;

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
      }
    }
  }

  &__hero {
    display: block;
    border-radius: 4px;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    aspect-ratio: 1.9;
    border: 0;
    background: $ui-base-color;
    object-fit: cover;
  }

  &__description {
    margin-bottom: 20px;
  }

  &__meta {
    display: flex;
    gap: 10px;
    max-width: 100%;

    &__column {
      flex: 0 0 auto;
      width: calc(50% - 5px);
      overflow: hidden;
    }
  }

  &__number {
    font-weight: 600;
    color: $primary-text-color;
    font-size: 14px;
  }

  &__number-label {
    color: $darker-text-color;
    font-weight: 500;
    font-size: 14px;
  }

  h4 {
    text-transform: uppercase;
    color: $darker-text-color;
    margin-bottom: 10px;
    font-weight: 600;
  }

  .account {
    padding: 0;
    border: 0;
  }

  .account__avatar-wrapper {
    margin-left: 0;
  }

  .spacer {
    margin: 10px 0;
  }
}
